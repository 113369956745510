import { Box, Typography } from '@mui/material'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { DefaultSnackbar } from '@shared/components/ui/DefaultSnackbar'
import { useSnackbar } from '@hooks/useSnackbar'
import {
  useActiveEventQuery
} from '@typings/graphql'
import { SupportEmailRow } from '@shared/components/ui/SupportEmailRow'

import { VerifiedTeamType } from '../typings/types'

import { VerifyView } from './VerifyView'
import { SubmitView } from './SubmitView'

const SubmissionViewContent: React.FC = () => {
  const { t } = useTranslation()

  const { data: evtData } = useActiveEventQuery()

  const {
    message, setShowSnackbar, showSnackbar, handleSuccess, handleError, severity
  } = useSnackbar()

  const [currentTeam, setCurrentTeam] = React.useState<VerifiedTeamType>()
  const [submitted, setSubmitted] = React.useState(false)

  const submissionClosed = React.useMemo<string>(() => {
    const today = new Date()
    const currentActiveEvent = evtData?.activeEvent?.active ? evtData.activeEvent : null

    if (!currentActiveEvent) {
      return 'home.noActiveEvent'
    }

    if (!currentActiveEvent?.submission) {
      return 'submission.closed'
    }

    if (today < new Date(currentActiveEvent?.startDate)) {
      return 'submission.closedEarly'
    }
    return ''
  }, [evtData])

  const handleVerify = (data: VerifiedTeamType) => {
    setCurrentTeam(data)

    if (data.hasResult) {
      setSubmitted(true)
    }
  }

  const handleSubmit = () => {
    setSubmitted(true)

    handleSuccess(t('submission.submitted'))
  }

  const submissionViewError = (error: any) => {
    handleError(error)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%'
      }}
    >
      {submissionClosed !== ''
        ? (
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%'
          }}
          >
            <Box
              sx={{
                width: '100%',
                mb: 4
              }}
            >
              <Typography variant='h5'>
                <Trans
                  i18nKey={submissionClosed}
                  values={{
                    eventName: evtData?.activeEvent?.name
                  }}
                  components={{ b: <b /> }}
                />
              </Typography>
            </Box>
          </Box>)
        : (<Box
            sx={{
              width: '100%',
              height: '100%'
            }}
        > { !submitted
          ? <Box>
            {!currentTeam
              ? (
                <VerifyView
                  onVerify={handleVerify}
                  onError={submissionViewError}
                />
                )
              : (
                <SubmitView
                  onSubmitted={handleSubmit}
                  currentTeam={currentTeam}
                  onError={submissionViewError}
                />
                )}

            {evtData?.activeEvent?.supportEmail && (
              <Box sx={{ mt: 2 }}>
                <SupportEmailRow title={t('submission.supportContact')} mail={evtData?.activeEvent?.supportEmail} />
              </Box>
            )}
          </Box>
          : (
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              height: '100%'
            }}
            >
              <Box
                sx={{
                  width: '100%',
                  mb: 4
                }}
              >
                <Typography
                  fontWeight="bold"
                  variant='h5'
                >
                  {currentTeam?.hasResult ? (t('submission.alreadySubmitted')) : t(('submission.submissionSuccess'))}
                </Typography>
              </Box>
            </Box>
            )
            }
        </Box>
          )
    }

      <DefaultSnackbar
        open={showSnackbar}
        onClose={() => setShowSnackbar(false)}
        severity={severity}
        message={message}
        sx={{
          position: 'fixed'
        }}
      />

    </Box>
  )
}

export const SubmissionView: React.FC = () => {
  return (
    <SubmissionViewContent />
  )
}
