import { Link, Typography } from '@mui/material'
import { palette } from '@theme/core/palette/palette'
import { fontSizes } from '@theme/core/typography/typography'
import React from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  url: string;
  label?: string;
};

export const JuryTeamWebsite: React.FC<Props> = ({ url, label }) => {
  const { t } = useTranslation()
  const handleLinkClick = React.useCallback(() => {
    window.open(url, '_blank')
  }, [url])

  return (
    <>
      <Typography
        variant='subtitle1'
        fontWeight="bold"
      >
        {label || t('common.website')}
      </Typography>
      <Link
        sx={{
          mt: 1,
          fontSize: fontSizes.sm,
          cursor: 'pointer',
          color: palette?.grey?.['700'],
          textDecorationColor: palette?.grey?.['700'],
          '&:hover': {
            color: palette?.grey?.['700'],
            textDecoration: 'underline',
            textDecorationColor: palette?.grey?.['700']
          }
        }}
        variant='body1'
        onClick={handleLinkClick}
      >
        {url}
      </Link>
    </>
  )
}
