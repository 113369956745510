import { Box, BoxProps, IconButton } from '@mui/material'
import { TextInput } from '@shared/components/inputs/TextInput'
import { themeIcons } from '@theme/icons'
import React from 'react'
import { useTranslation } from 'react-i18next'

export const VerifyInputs: React.FC<BoxProps> = (props) => {
  const { t } = useTranslation()

  const [showPassword, setShowPassword] = React.useState(false)

  return (
    <Box {...props}>
      <TextInput
        formKey="name"
        label={t('common.teamname')}
        type="text"
        hideMaxLength
        options={{
          required: true
        }}
        validationText={t('error.input.invalidTeamname')}
      />

      <TextInput
        hideMaxLength
        formKey="verificationCode"
        label={t('common.verificationCode')}
        type={showPassword ? 'text' : 'password'}
        sx={{
          mt: 2
        }}
        InputProps={{
          endAdornment: (
            <IconButton
              sx={{
                position: 'absolute',
                right: '8px'
              }}
              onClick={() => setShowPassword(!showPassword)}
            >
              {!showPassword ? <themeIcons.Visibility /> : <themeIcons.VisibilityOff />}
            </IconButton>
          )
        }}
      />
    </Box>
  )
}
