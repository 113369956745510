import React from 'react'
import { useNavigate } from 'react-router'
import { Box, Typography } from '@mui/material'
import { t } from 'i18next'
import ReactMarkdown from 'react-markdown'

import { JuryDataProvider, useJuryContext } from '../provider/JuryDataProvider'
import { JuryTeamLayout } from '../components/JuryTeamLayout'
import { JuryCriteriaItem } from '../components/JuryCriteriaItem'
import { JuryTeamWebsite } from '../components/JuryTeamWebsite'
import { JuryTeamAttachments } from '../components/JuryTeamAttachments'

const JuryTeamViewContent: React.FC = () => {
  const navigate = useNavigate()

  const { teamData, schemeData, eventData } = useJuryContext()

  const result = React.useMemo(() => {
    return teamData?.teamResult
  }, [teamData])

  const onCriteriaSelected = (criteriaId: string) => {
    navigate(`/jury/${teamData?.id}/${criteriaId}`)
  }

  const handleBackClicked = () => {
    navigate('/jury')
  }

  return (
    <JuryTeamLayout onBack={handleBackClicked}>
      <Box
        sx={{
          px: 2
        }}
      >
        <Typography
          variant='h3'
          sx={{
            mb: 3
          }}
        >
          {teamData?.name}
        </Typography>
        <Box>
          {
            !eventData?.rateable && (
              <Typography
                sx={{
                  color: 'error.main',
                  mb: 2
                }}
                fontWeight="bold"
                variant='subtitle1'
              >
                {t('jury.ratingClosed')}
              </Typography>
            )
          }

          <Typography
            sx={{
              mb: 1
            }}
            fontWeight="bold"
            variant='subtitle1'
          >
            {t('jury.rating')}
          </Typography>

          {schemeData?.schemeCriteria.map((criteria) => (
            <Box
              key={criteria.id}
              sx={{
                mb: 1
              }}
              onClick={() => onCriteriaSelected(criteria.id)}
            >
              <JuryCriteriaItem criteria={criteria} />
            </Box>
          ))}
        </Box>
        {result?.repository && (
        <Box sx={{
          mt: 3
        }}
        >
          <JuryTeamWebsite
            url={result.repository}
            label={t('common.repository')}
          />
        </Box>
        )}
        {result?.website && (
        <Box sx={{
          mt: 3
        }}
        >
          <JuryTeamWebsite url={result.website} />
        </Box>
        )}
        {result?.files?.length && (
        <Box sx={{
          mt: 3
        }}
        >
          <JuryTeamAttachments files={result.files} />
        </Box>
        )}

        {result && (
        <Box sx={{
          my: 3
        }}
        >
          <Typography
            variant='subtitle1'
            fontWeight="bold"
          >
            {t('common.description')}
          </Typography>
          <Box sx={{
            mt: 1,
            px: 2,
            py: 1,
            border: 'solid 1px #000000',
            backgroundColor: 'secondary.200'
          }}
          >
            <ReactMarkdown>
              {result.texts?.description || t('common.noDescription')}
            </ReactMarkdown>
          </Box>
        </Box>
        )}
      </Box>
    </JuryTeamLayout>
  )
}

export const JuryTeamView: React.FC = () => (
  <JuryDataProvider>
    <JuryTeamViewContent />
  </JuryDataProvider>
)
