import { Box, useTheme } from '@mui/material'
import { Logo } from '@shared/components/ui/Logo'
import { ShadowCard } from '@shared/components/ui/ShadowCard'
import React from 'react'
import { Outlet, useNavigate } from 'react-router'

type Props = {
  logoClickable?: boolean,
  large?: boolean
}

export const PublicLayout: React.FC<Props> = ({ logoClickable, large }) => {
  const { breakpoints } = useTheme()
  const navigate = useNavigate()

  const handleLogoClicked = () => {
    if (logoClickable) {
      navigate('/login')
    }
  }

  return (
    <Box sx={{
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'secondary.200'
    }}
    >
      <ShadowCard
        visuals
        sx={{
          ...(large
            ? {
                maxWidth: '80%'
              }
            : {
                width: '50%'
              }),
          minWidth: '50%',
          minHeight: '30%',
          maxHeight: '80%',
          [breakpoints.down('md')]: {
            width: '95%',
            maxWidth: '95%',
            maxHeight: '95%'
          }
        }}
        contentContainerProps={{
          sx: {
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            px: 0,
            [breakpoints.down('md')]: {
              py: 2
            }
          }
        }}
      >
        <Box
          sx={{
            flex: 0,
            pb: 4,
            px: 4,
            display: 'flex',
            [breakpoints.down('md')]: {
              pb: 2,
              px: 2
            }
          }}
        >
          <Box
            sx={{
              cursor: logoClickable ? 'pointer' : 'default'
            }}
            {...(logoClickable && { onClick: handleLogoClicked })}
          >
            <Logo />
          </Box>
        </Box>
        <Box sx={{
          flex: 1,
          overflow: 'hidden',
          position: 'relative'
        }}
        >
          <Box
            sx={{
              px: 4,
              height: '100%',
              overflow: 'auto',
              [breakpoints.down('md')]: {
                px: 2
              }
            }}
          >
            <Outlet />
          </Box>
        </Box>
      </ShadowCard>
    </Box>
  )
}
