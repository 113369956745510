import {
  DeleteRatingsMutation,
  RatingState,
  useChallengesQuery,
  useDeleteRatingsMutation,
  useSchemeByEventForEvaluationQuery,
  useTeamsWithEvaluationQuery
} from '@typings/graphql'
import React from 'react'
import { useArtificialLoading } from '@hooks/useArtificialLoading'
import { useParams } from 'react-router'
import { FetchResult } from '@apollo/client'

import {
  ChallengeModel,
  SchemeCriteriaData,
  SchemeModel,
  TeamCriteriaEvaluation,
  TeamModel,
  TeamRankingData
} from '../typings/types'

export type EvaluationDataProviderContextType = {
  criteriaFilter?: string
  challengeFilter?: string
  challengesData: ChallengeModel[]
  teamsData: TeamModel[]
  schemeData: SchemeModel | null
  rankingData: TeamRankingData[]
  loading: boolean,
  updateChallengeFilter: (value: string) => void
  handleDeleteRatings: () => Promise<FetchResult<DeleteRatingsMutation>>
  refetch: () => Promise<any>
}

const EvaluationDataProviderContext = React.createContext<EvaluationDataProviderContextType>(
  {} as any
)

export const EvaluationDataProvider:React.FC<React.PropsWithChildren> = ({ children }) => {
  const { id } = useParams<{ id: string }>()

  const [challengeFilter, setChallengeFilter] = React.useState<string>('')

  const { data, loading: dataLoading } = useSchemeByEventForEvaluationQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      eventId: id as string,
      all: true
    },
    skip: !id
  })

  const { data: challengesData, loading: challengesLoading } = useChallengesQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      eventId: id as string
    },
    skip: !id
  })

  const { data: eventTeamsData, loading: eventTeamsLoading, refetch } = useTeamsWithEvaluationQuery({
    fetchPolicy: 'network-only',
    variables: {
      eventId: id as string
    },
    skip: !id
  })

  const [deleteRatings] = useDeleteRatingsMutation({
    update (cache) {
      eventTeamsData?.teams.forEach((team) => {
        cache.modify({
          id: cache.identify({
            __typename: 'Team',
            id: team.id
          }),
          fields: {
            evaluation () {
              return []
            },
            ratingState () {
              return RatingState.None
            }
          }
        })
      })
    }
  })

  const schemeData = React.useMemo<SchemeModel | null>(() => {
    return data?.schemeByEvent || null
  }, [data])

  const teamsData = React.useMemo<TeamModel[]>(() => {
    return eventTeamsData?.teams || []
  }, [eventTeamsData])

  const getMappedCriteriaRatings = React.useCallback((
    team: TeamModel,
    criteria: SchemeCriteriaData[]
  ): TeamCriteriaEvaluation[] => {
    const criteriaRating: TeamCriteriaEvaluation[] = []

    for (const criteriaItem of criteria) {
      let childCriteria: TeamCriteriaEvaluation[] = []
      let value = 0

      if (criteriaItem.children && criteriaItem.children.length > 0) {
        childCriteria = getMappedCriteriaRatings(
          team,
          criteriaItem.children
        )

        value = Object.values(childCriteria).reduce((acc, child) => {
          return acc + child.value * (child.weighting / 100)
        }, 0)
      } else {
        value = team?.evaluation?.[criteriaItem.id] || 0
      }

      criteriaRating.push({
        id: criteriaItem.id,
        name: criteriaItem.texts.title,
        children: childCriteria,
        value,
        weighting: criteriaItem.weighting || 100 / criteria.length
      })
    }

    return criteriaRating
  }, [])

  const rankingData = React.useMemo(() => {
    if (!schemeData || !teamsData) {
      return []
    }

    const value = 0

    return teamsData.filter((team) => !challengeFilter || team.challengeId === challengeFilter).map((filtered) => {
      const mappedEvaluation = getMappedCriteriaRatings(
        filtered,
        schemeData.schemeCriteria
      )

      return {
        id: filtered.id,
        name: filtered.name,
        value,
        evaluation: mappedEvaluation
      }
    }).sort((a, b) => b.value - a.value)
  }, [challengeFilter, teamsData, schemeData])

  const loading = useArtificialLoading(500, [dataLoading, eventTeamsLoading, challengesLoading])

  const updateChallengeFilter = React.useCallback(async (value: string) => {
    await refetch()

    setChallengeFilter(value)
  }, [])

  const handleDeleteRatings = React.useCallback(async () => {
    return await deleteRatings({
      variables: {
        eventId: id as string
      }
    })
  }, [id])

  const value = React.useMemo<EvaluationDataProviderContextType>(() => {
    return {
      schemeData,
      challengesData: challengesData?.challenges || [],
      challengeFilter,
      teamsData,
      rankingData,
      loading,
      updateChallengeFilter,
      handleDeleteRatings,
      refetch
    }
  }, [
    challengesData,
    challengeFilter,
    teamsData,
    schemeData,
    rankingData,
    loading
  ])

  return (
    <EvaluationDataProviderContext.Provider value={value}>
      {children}
    </EvaluationDataProviderContext.Provider>
  )
}

export const useEvaluationContext = () => React.useContext(EvaluationDataProviderContext)
