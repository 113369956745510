import React from 'react'
import { Box, Typography } from '@mui/material'
import { themeIcons } from '@theme/icons'
import { useTranslation } from 'react-i18next'
import { RatingState } from '@typings/graphql'
import { RATING_STATE_COLORS } from '@shared/constants/color'

import { ActiveScheme } from '../typings/types'
import { useJuryContext } from '../provider/JuryDataProvider'

type Props = {
  criteria: ActiveScheme['schemeCriteria'][0]
}

export const JuryCriteriaItem: React.FC<Props> = ({ criteria }) => {
  const { t } = useTranslation()

  const { teamData } = useJuryContext()

  const ratingState = React.useMemo<RatingState>(() => {
    const childRatings = criteria.children.filter((child) => teamData?.ratings?.find((rating) => rating.criteriaId === child.id))

    if (childRatings.length === 0) {
      return RatingState.None
    } else if (childRatings.length === criteria.children.length) {
      return RatingState.Full
    }

    return RatingState.Partial
  }, [teamData, criteria.id])

  return (
    <Box
      sx={{
        border: '1px solid #000000',
        boxShadow: '4px 4px 0px 0px rgba(0,0,0)',
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: 'secondary.200',
        px: 2,
        py: 1
      }}
    >

      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        flex: 0,
        alignItems: 'center'
      }}
      >
        <Box sx={{
          border: '1px solid #000000',
          borderRadius: '50%',
          height: '12px',
          width: '12px',
          mr: 1,
          backgroundColor: RATING_STATE_COLORS[ratingState]
        }}
        />
      </Box>

      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'space-between'
      }}
      >
        <Typography
          variant='subtitle1'
          fontWeight="bold"
        >
          {t('jury.criteria', { name: criteria.texts.title, weight: criteria.weighting })}
        </Typography>
        <themeIcons.ArrowForward />
      </Box>
    </Box>
  )
}
