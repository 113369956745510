import { Box, Typography } from '@mui/material'
import React from 'react'
import { RatingInput } from '@shared/components/inputs/RatingInput'
import { useParams } from 'react-router'

import { useJuryContext } from '../provider/JuryDataProvider'

export const JuryTeamRatingInput: React.FC = () => {
  const { criteriaId } = useParams<{criteriaId: string}>()
  const { eventData, schemeData, teamData } = useJuryContext()

  const mappedSchemeData = React.useMemo(() => {
    const currentCriteria = schemeData?.schemeCriteria?.find((criteria) => criteria.id === criteriaId)

    return currentCriteria?.children?.map((criteria) => ({
      ...criteria,
      rating: teamData?.ratings?.find((rating) => rating.criteriaId === criteria.id)?.value || 0
    })) || []
  }, [schemeData, teamData])

  return (
    <Box>
      {mappedSchemeData.map((criteria) => (
        <Box
          key={criteria.id}
          sx={{
            mb: 4
          }}
        >
          <Typography
            variant='subtitle1'
            fontWeight="bold"
            sx={{
              mb: 1
            }}
          >
            {criteria.texts.title}
          </Typography>
          <RatingInput
            formKey={`ratings.${criteria.id}`}
            scale={schemeData?.ratingScale}
            disabled={!eventData?.rateable}
          />
        </Box>
      ))}
    </Box>
  )
}
