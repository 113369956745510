import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActionColumnRenderCell, actionColumnIconStyles, actionColumnModel } from '@shared/dataGrid/columns/actionColumn'
import { can } from '@utils/permissions'
import { BaseDataGrid } from '@shared/dataGrid/BaseDataGrid'
import { GRID_DETAIL_PANEL_TOGGLE_FIELD } from '@mui/x-data-grid-pro'
import { IconButton, Tooltip } from '@mui/material'
import { themeIcons } from '@theme/icons'
import { useAppSelector } from '@services/store/store'
import { getAuthUser } from '@services/store/slices/auth'
import { Roles } from '@typings/roles'

import { TeamButtonActions, TeamColumnType, TeamsRowModel } from '../typings/types'
import { useTeamsContext } from '../provider/TeamsDataProvider'
import { TeamDetailPanel } from '../components/TeamDetailPanel'
import { FORBIDDEN_COLUMNS } from '../constants/constants'

import { teamsDataGridColumns } from './teamsDataGridColumns'

type Props = {
  handleAction: (action: TeamButtonActions, rowModel: TeamsRowModel) => void
}

export const TeamsDataGrid: React.FC<Props> = ({ handleAction }) => {
  const { t } = useTranslation()

  const authUser = useAppSelector(getAuthUser)

  const { teamsData, loading } = useTeamsContext()

  const columnData = React.useMemo<TeamColumnType[]>(() => {
    const roleFilteredColumns = teamsDataGridColumns.filter((column) => {
      const forbidden = FORBIDDEN_COLUMNS[authUser?.role.key as Roles]

      return !forbidden?.includes(column.field)
    }, [])

    return [...roleFilteredColumns, {
      ...actionColumnModel,
      renderCell: (params: any) => (
        <ActionColumnRenderCell
          additionalActions={(
            <>
              {can(['teams:create']) && !params.row.active && (
                <Tooltip title={t('tooltips.activateTeam')}>
                  <IconButton onClick={() => handleAction(TeamButtonActions.ACTIVATE, params.row)}>
                    <themeIcons.Check sx={actionColumnIconStyles} />
                  </IconButton>
                </Tooltip>
              )}
              {can(['teams:create']) && !params.row.active && (
                <Tooltip title={t('tooltips.resendCode')}>
                  <IconButton onClick={() => handleAction(TeamButtonActions.RESEND_CODE, params.row)}>
                    <themeIcons.Mail sx={actionColumnIconStyles} />
                  </IconButton>
                </Tooltip>
              )}
              {can(['ratings:create', 'ratings:create.own']) && (
                <Tooltip title={t('tooltips.showRating')}>
                  <IconButton onClick={() => handleAction(TeamButtonActions.RATE, params.row)}>
                    <themeIcons.BarChart sx={actionColumnIconStyles} />
                  </IconButton>
                </Tooltip>
              )}
            </>
            )}
          {...(can('teams:update') && { onEdit: () => handleAction(TeamButtonActions.EDIT, params.row) })}
          {...(can('teams:delete') && { onDelete: () => handleAction(TeamButtonActions.DELETE, params.row) })}
        />
      )
    } as TeamColumnType].map((column) => {
      return {
        disableColumnMenu: true,
        headerName: column.hideHeader ? '' : t(`teams.column.${column.field}`),
        ...column
      }
    })
  }, [])

  return (
    <BaseDataGrid
      columns={columnData}
      rows={teamsData}
      autoPageSize={false}
      loading={loading}
      rowSelection={false}
      checkboxSelection={false}
      pinnedColumns={{ left: [GRID_DETAIL_PANEL_TOGGLE_FIELD] }}
      getDetailPanelContent={({ row }) => <TeamDetailPanel data={row} />}
      getDetailPanelHeight={() => 'auto'}
    />
  )
}
