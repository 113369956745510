import React from 'react'
import { Box, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { CenterCircularProgress } from '@shared/components/ui/CenterCircularProgress'
import { ElevatedButton } from '@shared/components/ui/ElevatedButton'
import { useAppDispatch, useAppSelector } from '@services/store/store'
import { CreateFeedbackAnswerInput } from '@typings/graphql'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { useSnackbar } from '@hooks/useSnackbar'
import { DefaultSnackbar } from '@shared/components/ui/DefaultSnackbar'
import { setFeedbackGiven } from '@services/store/slices/app'

import { FeedbackSubmissionInputs } from '../components/FeedbackSubmissionInputs'
import { FeedbackDataProvider, useFeedbackContext } from '../provider/FeedbackDataProvider'
import { FeedbackQuestionProgress } from '../components/FeedbackQuestionProgress'
import { FeedbackInput } from '../typings/types'

const FeedbackSubmissionViewContent: React.FC = () => {
  const { t } = useTranslation()
  const { breakpoints } = useTheme()

  const dispatch = useAppDispatch()

  const { feedbackGiven } = useAppSelector(({ app }) => app)
  const { eventData, loading, handleSubmitFeedback } = useFeedbackContext()

  const [submitting, setSubmitting] = React.useState(false)

  const { handleSubmit, formState: { isValid } } = useFormContext<FeedbackInput>()

  const {
    showSnackbar,
    severity,
    handleSuccess,
    handleError,
    setShowSnackbar,
    message
  } = useSnackbar()

  const [showFeedback, setShowFeedback] = React.useState(false)

  const submitFeedback = handleSubmit(async (input) => {
    setSubmitting(true)

    try {
      await handleSubmitFeedback(input)

      handleSuccess(t('feedbackSubmission.feedbackSuccess'))

      dispatch(setFeedbackGiven())
    } catch (error: any) {
      handleError(error)
    } finally {
      setSubmitting(false)
    }
  })

  return <Box sx={{
    height: '100%'
  }}
  >
    {(loading || !eventData)
      ? (
          loading
            ? (<CenterCircularProgress />)
            : (
              <Box sx={{
                mt: 6
              }}
              >
                <Typography
                  fontWeight="bold"
                  variant='h5'
                >
                  {t('common.noActiveEvent')}
                </Typography>
              </Box>
              )
        )
      : feedbackGiven
        ? (
          <Box sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            pb: 6
          }}
          >
            <Typography
              fontWeight="bold"
              variant='h5'
              maxWidth="500px"
            >
              {t('feedbackSubmission.thankYou')}
            </Typography>
          </Box>
          )
        : (
          <Box>
            <Box sx={{
              flex: 0,
              mt: showFeedback ? 3 : 0
            }}
            >
              <Typography
                variant="h3"
                sx={{
                  mb: 3
                }}
              >
                {eventData?.name}
              </Typography>
            </Box>
            {showFeedback
              ? (
                <>
                  <FeedbackQuestionProgress />

                  <FeedbackSubmissionInputs />

                  <ElevatedButton
                    variant="contained"
                    sx={{
                      mt: 2,
                      mb: 1,
                      [breakpoints.down('md')]: {
                        width: '100%'
                      }
                    }}
                    disabled={!isValid}
                    loading={submitting}
                    onClick={submitFeedback}
                  >
                    {t('action.submitFeedback')}
                  </ElevatedButton>
                </>
                )
              : (
                <>
                  <Typography sx={{
                    mb: 4,
                    maxWidth: '600px'
                  }}
                  >
                    {t('feedbackSubmission.description')}
                  </Typography>

                  <Box sx={{
                    display: 'flex',
                    [breakpoints.down('md')]: {
                      alignItems: 'center',
                      pr: 0.5,
                      pb: 0.5
                    }
                  }}
                  >
                    <ElevatedButton
                      onClick={() => setShowFeedback(true)}
                      sx={{
                        width: 'max-content',
                        [breakpoints.down('md')]: {
                          width: '100%'
                        }
                      }}
                    >
                      {t('feedbackSubmission.startFeedback')}
                    </ElevatedButton>
                  </Box>
                </>
                )}
          </Box>
          )}

    <DefaultSnackbar
      open={showSnackbar}
      message={message}
      onClose={() => setShowSnackbar(false)}
      sx={{
        position: 'fixed'
      }}
      severity={severity}
    />
  </Box>
}

export const FeedbackSubmissionView: React.FC = () => {
  const formData = useForm<CreateFeedbackAnswerInput[]>({
    defaultValues: [],
    mode: 'onChange'
  })

  return (
    <FeedbackDataProvider>
      <FormProvider {...formData}>
        <FeedbackSubmissionViewContent />
      </FormProvider>
    </FeedbackDataProvider>
  )
}
