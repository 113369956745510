import { Box, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { DefaultSnackbar } from '@shared/components/ui/DefaultSnackbar'
import { useSnackbar } from '@hooks/useSnackbar'
import { ElevatedButton } from '@shared/components/ui/ElevatedButton'
import { useAppDispatch, useAppSelector } from '@services/store/store'
import { setRegisteredTeam } from '@services/store/slices/app'
import { CenterCircularProgress } from '@shared/components/ui/CenterCircularProgress'
import { SupportEmailRow } from '@shared/components/ui/SupportEmailRow'

import { RegisterTeamDataProvider, useRegisterTeamContext } from '../provider/RegisterTeamDataProvider'
import { RegisterTeamDialog } from '../components/RegisterTeamDialog'

const TeamRegistrationViewContent: React.FC = () => {
  const { t } = useTranslation()

  const { teamData, eventData, loading } = useRegisterTeamContext()

  const [showDialog, setShowDialog] = React.useState(false)

  const dispatch = useAppDispatch()

  const { registeredTeam } = useAppSelector(({ app }) => app)

  const { message, handleError, handleSuccess, severity, setShowSnackbar, showSnackbar } = useSnackbar()

  const handleOnSuccess = async () => {
    handleSuccess(t('teams.createSuccess'))
    dispatch(setRegisteredTeam())
    setShowDialog(false)
  }

  const handleOnError = async (error: any) => {
    handleError(error)
  }

  const handleOnClose = async () => {
    setShowDialog(false)
  }

  const onHandleAction = () => {
    setShowDialog(true)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <Box
        sx={{
          width: '100%'
        }}
      >
        {(loading || !eventData)
          ? (
              loading
                ? (<CenterCircularProgress />)
                : (
                  <Box sx={{
                    mt: 3
                  }}
                  >
                    <Typography
                      fontWeight="bold"
                      variant='h5'
                    >
                      {t('common.noActiveEvent')}
                    </Typography>
                  </Box>
                  )
            )
          : <>
            <Typography

              fontWeight="bold"
            >{t('registerTeam.alreadyRegisteredTeams')}</Typography>
            <Box
              mb={3}
              sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}
            >
              {teamData?.map((item) => (
                <Box key={item.name} pt={1} pb={1} mr={2} >
                  <Box
                    sx={{
                      p: 2,
                      mr: '4px',
                      backgroundColor: 'secondary.100',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      border: 'solid 1px rgba(0,0,0)'
                    }}
                  >
                    <Typography>{item.name}</Typography>
                  </Box>
                </Box>

              ))}
            </Box>

            {eventData.registration
              ? (
                <Box sx={{
                  display: 'inline-block'
                }}
                >
                  <Tooltip
                    title={t('registerTeam.alreadyRegistered')}
                    disableHoverListener={!registeredTeam}
                    followCursor
                  >
                    <Box>
                      <ElevatedButton
                        onClick={onHandleAction}
                        disabled={registeredTeam || !eventData.registration}
                        sx={{
                          mt: 1
                        }}
                      >
                        {t('action.registerTeam')}
                      </ElevatedButton>
                    </Box>
                  </Tooltip>
                  {eventData.supportEmail && (
                    <Box sx={{ mt: 2 }}>
                      <SupportEmailRow mail={eventData.supportEmail} />
                    </Box>
                  )}
                </Box>
                )
              : (
                <Typography
                  variant='h5'
                  sx={{
                    fontWeight: 'bold',
                    mt: 2
                  }}
                >{t('registerTeam.closed')}</Typography>)}
          </>
        }

      </Box>

      <DefaultSnackbar
        open={showSnackbar}
        onClose={() => setShowSnackbar(false)}
        severity={severity}
        message={message}
        sx={{
          position: 'fixed'
        }}
      />

      <RegisterTeamDialog open={showDialog}
        onError={handleOnError}
        onSuccess={handleOnSuccess}
        onClose={handleOnClose}
      >
      </RegisterTeamDialog>

    </Box>
  )
}

export const TeamRegistrationView: React.FC = () => {
  return (
    <RegisterTeamDataProvider>
      <TeamRegistrationViewContent />
    </RegisterTeamDataProvider>
  )
}
