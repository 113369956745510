import { createSlice } from '@reduxjs/toolkit'

type AppState = {
  feedbackGiven: boolean,
  registeredTeam: boolean
};

const slice = createSlice({
  name: 'app',
  initialState: {
    feedbackGiven: false,
    registeredTeam: false
  } as AppState,
  reducers: {
    setFeedbackGiven: (
      state
    ) => {
      state.feedbackGiven = true
    },
    setRegisteredTeam: (state) => {
      state.registeredTeam = true
    }
  }
})

export const { setFeedbackGiven, setRegisteredTeam } =
  slice.actions

export default slice.reducer
