import React from 'react'
import { Box, Button, Typography } from '@mui/material'
import { CenterCircularProgress } from '@shared/components/ui/CenterCircularProgress'
import { useTranslation } from 'react-i18next'
import { themeIcons } from '@theme/icons'

import { useJuryContext } from '../provider/JuryDataProvider'

type Props = React.PropsWithChildren & {
  headerExtension?: React.ReactNode
  footerExtension?: React.ReactNode
  onBack: () => void
  sx?: React.ComponentProps<typeof Box>['sx']
}

export const JuryTeamLayout: React.FC<Props> = ({ onBack, headerExtension, footerExtension, sx, children }) => {
  const { t } = useTranslation()

  const { loading, teamData } = useJuryContext()

  return (
    <Box sx={{
      height: '100%',
      overflow: 'hidden',
      ...sx
    }}
    >
      {loading
        ? (
          <CenterCircularProgress />
          )
        : (
          <Box sx={{
            height: '100%',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column'
          }}
          >
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              backgroundColor: '#ffffff',
              flex: 0,
              px: 2,
              pt: 2,
              mb: 1.5
            }}
            >
              <Button
                variant="text"
                disableRipple
                sx={{
                  p: 0
                }}
                startIcon={<themeIcons.ArrowBack/>}
                onClick={onBack}
              >
                <Typography variant='subtitle1' fontWeight="bold">
                  {t('common.back')}
                </Typography>
              </Button>
              {headerExtension}
            </Box>

            <Box sx={{
              flex: 1,
              overflow: 'auto'
            }}
            >
              { !teamData
                ? (
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    pt: 10
                  }}
                  >
                    <Typography variant='h4'
                      sx={{
                        fontWeight: 'bold',
                        textAlign: 'center',
                        px: 2
                      }}
                    >
                      {t('jury.teamNotFound')}
                    </Typography>
                  </Box>
                  )
                : children

            }
            </Box>
            <Box sx={{
              flex: '0 0 auto',
              backgroundColor: '#ffffff'
            }}
            >
              {footerExtension}
            </Box>
          </Box>
          )
      }
    </Box>
  )
}
